<template>
  <div class="fragment">
    <BalancePayoneerHistoryHead
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeFilter="changeFilter"
        :showFilter="showFilter"
        :countFilterParams="countFilterParams"
    />

    <div class="table-filter-wrap">

      <BalancePayoneerHistoryFilter
          :showFilter="showFilter"
          @close="closeFilter"
          @changeFilter="changeFilter"
          @resetFilter="$emit('resetFilter')"
          :filterGetParams="filterGetParams"
          :PAYMENT_HISTORY_TYPE="PAYMENT_HISTORY_TYPE"
      />

      <BalancePayoneerHistoryTable
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @downloadFiles="type => $emit('downloadFiles', type)"
          :countFilterParams="countFilterParams"
          :PAYMENT_HISTORY_TYPE="PAYMENT_HISTORY_TYPE"
      />
    </div>
  </div>
</template>

<script>
  import BalancePayoneerHistoryHead from "./BalancePayoneerHistoryHead/BalancePayoneerHistoryHead";
  import BalancePayoneerHistoryFilter from "./BalancePayoneerHistoryFilter/BalancePayoneerHistoryFilter";
  import BalancePayoneerHistoryTable from "./BalancePayoneerHistoryTable/BalancePayoneerHistoryTable";

  export default {
    name: "BalancePayoneerHistoryTableUser",
    components: {
      BalancePayoneerHistoryHead,
      BalancePayoneerHistoryFilter,
      BalancePayoneerHistoryTable,
    },

    props: [
      'countFilterParams',
      'filterGetParams',
      'PAYMENT_HISTORY_TYPE',
    ],

    data(){
      return{
        showFilter: false,
        filterId: '',
        filterName: '',
        forPage: 10,
        page: 1,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeShopType(index) {
        this.$emit('changeShopType', index)
      },


      changeFilter(data) {
        this.$emit('changeFilter', data)
      },
    }
  }
</script>

<style scoped>

</style>
