<template>
  <div class="page-content">
    <Breadcrumb/>
    <SearchEngine
        v-if="isAdmin"
        class="mb-3"
    />
    <div class="detail-page balance-history-page">
      <div class="detail-page__left"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['payments_currentBalance', 'payments_withdrowedTotally', 'payments_AddMoneySum', 'payments_earnedTotally'])"></span>

        <CardLeftBlock class="balance-history-page__left-block"
                       :backgroundImage="'balance'"
                       :name="$t('payments_currentBalance.localization_value.value')"
                       :value="'$' + parseFloat($store.getters.getBalancePayoneerHistoryCommonList.balance.user_balance).toFixed(2)"
                       v-if="Object.hasOwnProperty.call($store.getters.getBalancePayoneerHistoryCommonList, 'balance')"
        >
<!--                       :value="'$' + (loadUserAuthorizedData.user.user_balances.length > 0 ? parseFloat(loadUserAuthorizedData.user.user_balances[0].balance).toFixed(2) : 0)"-->
          <template slot="body">
            <div class="fragment d-flex flex-column">
            </div>
          </template>
        </CardLeftBlock>
      </div>
      <div class="detail-page__right">
        <BalancePayoneerHistoryTable/>
      </div>
    </div>

  </div>
</template>

<script>
  import Breadcrumb from "../../templates/Breadcrumb/Breadcrumb.vue";
  import CardLeftBlock from "../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import BalancePayoneerHistoryTable from "./components/BalancePayoneerHistoryTable/BalancePayoneerHistoryTable";
  import SearchEngine from "../../coreComponents/SearchEngine/SearchEngine";

  export default {
    name: "BalancePayoneerHistoryModule",

    components:{
      SearchEngine,
      Breadcrumb,
      CardLeftBlock,
      BalancePayoneerHistoryTable,
    },

    data(){
      return {

      }
    },

  }
</script>

<style scoped>

  @media (min-width: 1220px) {
    .change-balance-line:first-child {
      margin-top: 20px;
    }
  }
</style>
