<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
        <SearchSelect
            :options="options"
            :label="$t('shop_user.localization_value.value')"
            :optionsLabel="'email.contacts[0]'"
            :functionSearch="functionSearchUserFilter"
            @change="changeUsersFilter"
            :selected="userName"
            :typeSelect="'users'"
            :userEmail="true"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['payments_date'])"></span>
        <DatePickerDefault
            :label="$t('payments_date.localization_value.value')"
            v-model="date"
        >
          <template slot="body">
            <date-picker
                    v-model="date"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy ~ mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['payments_transactionAmount'])"></span>
        <DefaultInput
            :label="$t('payments_transactionAmount.localization_value.value')"
            :type="'text'"
            v-model="transactionAmount"
            @blur="blurAmount"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['payments_moneyWithdraw', 'payments_moneyEarned', 'warehouseManagement_type'])"></span>
        <DefaultSelect
                :options="PAYMENT_HISTORY_TYPE"
                :label="$t('warehouseManagement_type.localization_value.value')"
                @change="changeType"
                :optionsLabel="'name'"
        />
      </div>

<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit"-->
<!--              @click="editTranslate(['payments_transaction'])"></span>-->
<!--        <DefaultInput-->
<!--            :label="$t('payments_transaction.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="transactionNum"-->
<!--        />-->
<!--      </div>-->
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import { usersFunc } from "../../../../../../../mixins/usersMixins/usersFunc";

  export default {
    name: "BalancePayoneerHistoryFilter",
    components: {
      SearchSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
      DefaultSelect,
    },

    props: [
      'showFilter',
      'filterGetParams',
      'PAYMENT_HISTORY_TYPE',
    ],

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',
        transactionNum: this.filterGetParams.transactionNum ? this.filterGetParams.transactionNum : '',
        type: this.filterGetParams.type ? this.filterGetParams.type : '',
        transactionAmount: this.filterGetParams.transactionAmount ? this.filterGetParams.transactionAmount : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        options: [],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.transactionNum = newVal.transactionNum ? newVal.transactionNum : ''
        this.type = newVal.type ? newVal.type : ''
        this.transactionAmount = newVal.transactionAmount ? newVal.transactionAmount : ''
        this.userId = newVal.userId ? newVal.userId : ''
        this.userName = newVal.userName ? newVal.userName : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, [
          'date',
          'transactionNum',
          'type',
          'transactionAmount',
          'userId',
          'userName',
        ])

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, [
          'transactionNum',
          'type',
          'transactionAmount',
          'userId',
          'userName',
        ])
        data.date = this.changeDateParams(this, 'date')

        this.$emit('changeFilter', data)
      },

      changeType(val) {
        this.type = val.type
      },

      blurAmount() {
        if(this.transactionAmount.indexOf('.00')){
          this.transactionAmount = this.transactionAmount.replace('.00', '')
        }
      }

    },
  }
</script>

<style scoped>

</style>
