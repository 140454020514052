<template>

  <BalancePayoneerHistoryTableUser
          @filter="filter"
          :filterGetParams="filterGetParams"
          @changeFilter="changeFilter"
          @showMore="showMore"
          @resetFilter="resetFilter"
          @downloadFiles="downloadFiles"
          :countFilterParams="countFilterParams"
          :PAYMENT_HISTORY_TYPE="PAYMENT_HISTORY_TYPE"
  />


</template>

<script>
  import BalancePayoneerHistoryTableUser from "./BalancePayoneerHistoryTableUser/BalancePayoneerHistoryTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "BalancePayoneerHistoryTable",

    components: {
      BalancePayoneerHistoryTableUser,
    },

    mixins: [routeFilter],

    data() {
      return {
        forPage: this.$store.getters.getBalancePayoneerHistoryForPage,
        page: 1,
        onePage: 1,
        filterDate: [],
        filterTransactionNum: '',
        filterType: '',
        filterTransactionAmount: '',
        filterUserId: '',
        filterUserName: '',
        filterGetParams: {},
        countFilterParams: 0,

        PAYMENT_HISTORY_TYPE: [
          {
            name: this.$t('payments_moneyWithdraw.localization_value.value'),
            type: 'remove_money',
          },
          {
            name: this.$t('payments_moneyEarned.localization_value.value'),
            type: 'add_money',
          }
        ]
      }
    },

    created() {
      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          transactionNum: this.$route.query.transactionNum,
          type: this.$route.query.type,
          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
          transactionAmount: this.$route.query.transactionAmount,
        }
      },

      changeFilter(data) {
        data.page = this.onePage
        data.count = this.forPage

        this.getFilterParam()
        this.changeRouteParams(this.filterGetParams, data)

        this.filter()
      },

      resetFilter() {
        this.$router.push({path: '', query: {}})

        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)


        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextBalancePayoneerHistoryPage', true)

        // save filter params in store
        this.$store.commit('setBalancePayoneerHistoryFilter', window.location.search)

        this.$store.dispatch('fetchBalancePayoneerHistory', url).then(() => {
          // skip get next page
          this.$store.commit('setNextBalancePayoneerHistoryPage', false)
        })

        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()

        // if (this.filterTransactionNum !== '') myQuery.where('transaction_id', this.filterTransactionNum)
        if (this.filterType !== '') myQuery.where('payment_type', this.filterType)
        if (this.filterTransactionAmount !== '') myQuery.where('amount', this.filterTransactionAmount)
        if (this.filterUserId !== '') myQuery.where('user_id', this.filterUserId)

       myQuery.where('pay_system_type_id', 1)


        this.filterDate = this.$route.query.date !== undefined ? this.$route.query.date : ''

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
            'between_created_at',
            date
          )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      showMore() {
        this.generateNextPAgeUrl(this)

        this.filter(true)
      },

      checkCountFilter(withoutParams = false) {
        this.countFilterParams = this.checkCountFilterFromUrl(this, withoutParams)
      },

      downloadFiles(type) {
        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage)

        this.$store.dispatch('getExportBalancePayoneerHistory', {filter: url , type: type}).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              const blob = new Blob([response.data], {type: `application/${type}`})
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = `balance-payoneer-history.${type}`
              link.click()
              URL.revokeObjectURL(link.href)

              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        }).catch(error => this.createErrorLog(error))

      },
    },

  }
</script>

<style scoped>

</style>
