<template>
  <div class="table-filter-wrap">
    <div class="site-table-wrap products-table"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate([
              'payments_orderShipment',
              'payments_date',
              'payments_transactionAmount',
              'payments_userBalance',
              'payments_type',
              'payments_comment',
              'payments_transaction',
              'payments_moneyEarned',
              'payments_moneyWithdraw',
              'payments_complaintRequestId',
              ])"></span>

      <template v-if="$store.getters.getBalancePayoneerHistory.length > 0 && $store.getters.getBalancePayoneerHistoryLoading !== true">
        <table class="site-table mt-0" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{$t('payments_date.localization_value.value')}}</th>
            <th class="text-right">{{$t('payments_transactionAmount.localization_value.value')}},  $</th>
            <th class="text-right">{{$t('payments_userBalance.localization_value.value')}}</th>
            <th>{{$t('payments_type.localization_value.value')}}</th>
            <th>{{$t('payments_comment.localization_value.value')}}</th>
            <th>{{$t('payments_transaction.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getBalancePayoneerHistory" :key="index">

            <td style="text-transform: capitalize;">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>
            <td align="right">
              <template v-if="item.payment_type === 'remove_money'">-</template>
              $ {{item.amount | number('0.00')}}
            </td>
            <td align="right">
              $ {{item.user_balance | number('0.00')}}
            </td>
            <td>
              <span v-if="item.payment_type === 'add_money'">
                {{$t('payments_moneyEarned.localization_value.value')}}
              </span>
              <span v-if="item.payment_type === 'remove_money'">
                {{$t('payments_moneyWithdraw.localization_value.value')}}
              </span>
            </td>

            <td>
              <TooltipTextHelper
                  :fullTextWidth="300">
                <template slot="text">
                  {{item.comment}}
                </template>
                <template slot="paragraph">
                  {{item.comment}}
                </template>
              </TooltipTextHelper>
            </td>

            <td>
              <template v-if="item.payment_type === 'add_money'">
                <router-link
                    class="site-link text-decoration-underline btn-style brown"
                    :to="$store.getters.GET_PATHS.financeNewPaymentsPayoneer + '?order=' + item.model_id">
                  #{{item.model_id}}
                </router-link>
              </template>

              <template v-if="item.payment_type === 'remove_money'">
                <router-link
                    class="site-link text-decoration-underline btn-style brown"
                    :to="$store.getters.GET_PATHS.financeRequestFunds + '?id=' + item.model_id">
                  #{{item.model_id}}
                </router-link>
              </template>
            </td>
          </tr>


          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list pt-0">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getBalancePayoneerHistory"
                 :key="index"
            >
              <BalancePayoneerHistoryTableMobile
                :item="item"
              />
            </div>
          </div>
        </div>

      </template>
      <template v-if="$store.getters.getBalancePayoneerHistoryLoading === false && $store.getters.getBalancePayoneerHistory.length === 0">
        <NoResult
                :countFilterParams="countFilterParams"
                :title="$t('common_noDataYet.localization_value.value')"
                :titleWithFilter="$t('common_noResult.localization_value.value')"
                :text="$t('common_noDataYetTxt.localization_value.value')"
                :textWithFilter="$t('common_noResultSeems.localization_value.value')"
                @resetFilter="$emit('resetFilter')"
        />
      </template>


      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore class=""
                    v-if="$store.getters.getBalancePayoneerHistoryCommonList.next_page_url !== null && $store.getters.getBalancePayoneerHistory.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextBalancePayoneerHistoryPage}"
                    :count="$store.getters.getBalancePayoneerHistoryCommonList.total - $store.getters.getBalancePayoneerHistoryForPage * $store.getters.getBalancePayoneerHistoryCommonList.current_page < $store.getters.getBalancePayoneerHistoryForPage ?
                  $store.getters.getBalancePayoneerHistoryCommonList.total - $store.getters.getBalancePayoneerHistoryForPage * $store.getters.getBalancePayoneerHistoryCommonList.current_page:
                  $store.getters.getBalancePayoneerHistoryForPage"
          />

          <ExportBtn
                  class="table-bottom-btn__right"
                  @downloadFiles="type => $emit('downloadFiles', type)"
          />

        </div>
      </div>
    </div>



  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import BalancePayoneerHistoryTableMobile from "./BalancePayoneerHistoryTableMobile/BalancePayoneerHistoryTableMobile";
  // import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {
    CUSTOMERS_PAYMENTS_STATUSES,
    EXPRESS_DELIVERY_SERVICES,
    INTERNAL_DELIVERY_SERVICES, INVOICES_TYPES, PAYMENT_TYPE_NAME, TRANSACTION_SLUICE_TYPES
  } from "../../../../../../../staticData/staticVariables";
  import {ordersInboundTableMixin} from "../../../../../../../mixins/ordersInbound/ordersInboundMixin";
  import {FBMMixinsHelper} from "../../../../../../../mixins/FBMMixins/FBMMixins";
  import {consolidationMixin} from "../../../../../../../mixins/consolidationMixins/consolidationMixin";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";

  export default {
    name: "BalancePayoneerHistoryTable",
    components: {
      TooltipTextHelper,
      // ValueHelper,
      ShowMore,
      ExportBtn,
      NoResult,
      BalancePayoneerHistoryTableMobile,
    },

    mixins: [mixinDetictingMobile, ordersInboundTableMixin, FBMMixinsHelper, consolidationMixin],

    props: {
      countFilterParams: Number,
    },

    data(){
      return{
        CUSTOMERS_PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
        EXPRESS_DELIVERY_SERVICES: EXPRESS_DELIVERY_SERVICES,
        INTERNAL_DELIVERY_SERVICES: INTERNAL_DELIVERY_SERVICES,
        PAYMENT_TYPE_NAME: PAYMENT_TYPE_NAME,
        INVOICES_TYPES: INVOICES_TYPES,
      }
    },

    methods: {

      hasInvoiceInstance(item){
        return item?.invoice?.invoice_instance
      },

      fromPayPalCase(item) {
        return item.orderInstance && item.orderInstance?.complaint_type_id
      },

      fromInvoice(item) {
        return item.invoice
      },

      fromOutput(item) {
        return item.orderInstance && item.orderInstance.balance_before_output
      },

      getRouteBySluiceType(item) {
        if(item.transaction_sluice === TRANSACTION_SLUICE_TYPES.INTERNAL)
          return this.$store.getters.GET_PATHS.financeMyPayments

        return this.$store.getters.GET_PATHS.cusPayments
      },

      consoleValue(val) {
        console.log(val.comment, val);
      }
    },

  }
</script>

<style scoped>
  .site-table thead th:first-child,
  .site-table tbody td:first-child{
    /*padding-left: 8px;*/
    /*padding-right: 0;*/
  }

  .site-table thead th:nth-child(2),
  .site-table tbody td:nth-child(2){
    /*padding-left: 1px;*/
  }


  .comment-row{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 290px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }

  .payment-comment{
    width: 300px;
  }
</style>
